module.exports = {
    LOCALE: "fa",
    ROLES: [
        {key: "guest", value: "Guest"},
        {key: "user", value: "User"},
        {key: "admin", value: "Admin"}
    ],
    TEST_URL: [
        "localhost",
        "testadmin.onmiz.net"
    ],
    SERVERS_DROPDOWN: [
        {key: "https://test.server.com", value: "Test Server"},
        {key: "http://5.63.9.68:8109/v2/", value: "Production Server"}
    ],
    SERVERS: {
        base: "http://5.63.9.68:8109/v2/",
        log: "http://log.server.com",
        backup: "http://backup.server.com",
        news: "http://news.server.com",
        chat: "http://chat.server.com"
    },
};