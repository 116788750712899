module.exports = {
    direction: "ltr",
    texts: {
        title: "Admin Panel",
        login: "Login",
        logout: "Logout",
        basic_info: "Basic Info",

        countries: "Countries",
        cities: "Cities",
        categories: "Categories",
        businesses: "Businesses",

        dashboard: "Dashboard",
        close: "Close",
        connection_error: "Connecting to the server failed",
        internal_error: "Internal Error",
        username: "Username",
        password: "Password",
        add_new_entity: "Add New %1",
        edit_entity: "Edit %1",
        correct: "Correct",
        enter_field: "Please enter this field",
        invalid_field: "The field type is invalid",
        required_field: "This field is required",
        search: "Search",
        count: "Count",
        items_in_page: "Items in page",
        all: "All",
        confirmed: "Confirmed",
        not_confirmed: "Not Confirmed",
        excel: "Excel",
        save: "Save",
        choose: "Choose",
        operations: "Operations",
        remove_confirm: "Are you sure you want to delete this record?",
        confirm: "Confirm",
        reject: "Reject",
        edit: "Edit",
        remove: "Remove",
        from: "From",
        to: "To"
    }
};