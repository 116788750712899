import React, {useContext, useEffect, useMemo, useState} from "react";
import {Form, Button, Alert} from "react-bootstrap";
import {xhr} from "../index";
import Field from "./field";
import {Loading} from "../";
import GetForeignData from "../getForeignData";
import {DBContext} from "../../providers/dbProvider";
import {Context} from "../../providers/mainProvider";

export default function Item(props) {
    const {locale} = useContext(Context);

    const [message, setMessage] = useState('');
    const [id, setId] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [mapCircle, setMapCircle] = useState({});
    const [foreignValues, setForeignValues] = useState({});
    const {collections, getData} = useContext(DBContext);

    const base = useMemo(() => {
        return props.props.base;
    }, []);
    const item = useMemo(() => {
        return props.props.item;
    }, []);
    const title = useMemo(() => {
        let temp = '';
        if(item.id){
            setId(item.id);
            temp = item.custom_edit_title ?? locale.edit + ' ' + base.entity;
        }else{
            temp = item.custom_add_title ?? locale.add_new_entity.replace('%1', base.entity);
        }
        return temp;
    }, []);

    const afterChange = (name, value) => {
        console.log(name, value);
        if(name === "radius"){
            setMapCircle(mapCircle.change("radius", value));
        }
    };

    const changeField = (name, value) => {
        setData(data.change(name, value));
        if(base.model[name] && base.model[name].afterChange){
            afterChange(name, value);
        }
    };

    const showResult = response => {
        if(response.status){
            setMessage(<Alert variant="success">{response.note}</Alert>);
        }else{
            setMessage(<Alert variant="danger">{response.note}</Alert>);
        }
        window.scroll(0, 0);
        setLoading(false);
    };

    const submit = e => {
        e.preventDefault();
        setLoading(true);
        let sendData = {...data};
        try {
            let JSONFields = props.props.item.fields.filter(field => field.type === "json");
            if(JSONFields.length > 0){
                for(let i = 0; i < JSONFields.length; i++){
                    sendData[JSONFields[i].name] = JSON.parse(sendData[JSONFields[i].name]);
                }
            }
            if(id){
                new xhr({
                    url: base.module,
                    data: sendData
                }).Put(showResult);
            }else{
                new xhr({
                    url: base.module,
                    data: sendData
                }).Post(showResult);
            }
        } catch (e) {
            setMessage(<Alert variant="danger">{e.message}</Alert>);
        }
    };

    useEffect(() => {
        if(id) {
            new xhr({
                url: base.module + '/' + id,
                server: base.server
            }).GetOne(response => {
                let JSONFields = props.props.item.fields.filter(field => field.type === "json");
                if(JSONFields.length > 0){
                    for(let i = 0; i < JSONFields.length; i++){
                        response[JSONFields[i].name] = JSON.stringify(response[JSONFields[i].name]);
                    }
                }
                setData(response);
            });
        }else{
            let firstData = {};
            let field;
            for(let i = 0; i < props.props.item.fields.length; i++){
                field = props.props.item.fields[i];
                if(field.default){
                    firstData[field.name] = field.default;
                }
            }
            console.log(firstData);
            setData(props.props.item.initial_data ?? firstData);
        }

        GetForeignData({
            collections,
            getData,
            model: base.model,
        }, fv => setForeignValues(fv));
    }, []);

    return (
        <div>
            <h2>{title}</h2>
            {message}
            {data && (Object.keys(data).length > 0 || !id) ?
                <Form onSubmit={submit}>
                    {item && item.fields.map(field => <Field key={`field_${field.name}`} props={{
                        id,
                        base,
                        field,
                        data,
                        mapCircle,
                        foreignValues,
                        changeField
                    }}/>)}
                    <Button type="submit" disabled={loading ? "true" : ""}>{loading ? <Loading theme="light"/> : locale.save}</Button>
                </Form>
                :
                <Loading/>
            }
        </div>
    );
}