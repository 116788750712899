module.exports = {
    direction: "rtl",
    texts: {
        title: "پنل مدیریت",
        login: "ورود",
        logout: "خروج",
        basic_info: "اطلاعات پایه",

        countries: "کشورها",
        cities: "شهرها",
        categories: "دسته‌بندی‌ها",
        businesses: "کسب و کارها",

        dashboard: "داشبود",
        close: "بستن",
        connection_error: "ارتباط با سرور برقرار نشد.",
        internal_error: "خطای سیستمی رخ داد.",
        username: "نام کاربری",
        password: "رمز عبور",
        add_new_entity: "افزودن %1 جدید",
        edit_entity: "ویرایش %1",
        correct: "درست",
        invalid_field: "مقدار این فیلد درست نیست",
        required_field: "لطفاً این فیلد را وارد کنید",
        search: "جستجو",
        count: "تعداد",
        items_in_page: "تعداد نمایش در هر صفحه",
        all: "همه",
        confirmed: "تأیید شده",
        not_confirmed: "تأیید نشده",
        excel: "اکسل",
        save: "ثبت",
        choose: "انتخاب",
        operations: "عملیات",
        remove_confirm: "آیا مطمئنید که می خواهید این مورد را حذف کنید؟",
        confirm: "تأیید",
        reject: "خارج کردن از حالت تأیید",
        edit: "ویرایش",
        remove: "حذف",
        from: "از",
        to: "تا"
    }
};