export default async function GetForeignData(props, callback) {
    let {
        model,
        collections,
        getData
    } = props;
    let fields = Object.entries(model);
    let foreignFields = fields.filter(field => field[1].foreign);
    let foreignDef = {};
    let result = {};
    for(let i = 0; i < foreignFields.length; i++){
        foreignDef = foreignFields[i][1] ? foreignFields[i][1].foreign : null;
        if(foreignDef.module && foreignDef.field){
            if(collections.indexOf(foreignDef.module) > -1){
                await getData(foreignDef.module, foreignDef).then(data => {
                    let convertedValues, res, name, item;
                    let foreignDef = data.otherProps;
                    convertedValues = [];
                    name = '';
                    for(let j = 0; j < data.list.length; j++){
                        item = data.list[j];
                        name = '';
                        if(foreignDef){
                            for(let k = 0; k < foreignDef.result.length; k++){
                                res = foreignDef.result[k];
                                name += res.static ?? item[res];
                            }
                        }
                        convertedValues.push({id: item.id, name});
                    }
                    result[foreignFields[i][0]] = convertedValues;
                });
            }else{
                // Get from the server
            }
        }
    }
    callback(result);
}
