const services = props => {
    return {
        base: {
            entities: "Businesses",
            entity: "Business",
            module: "services",
            path: "/businesses",
            default_order: 'last_update',
            confirm_field: "confirmed",
            confirmed_extra_fields: ["mobile_confirmed"],
            confirm_other_fields: [{name: "user.id", alias: "user_id"}],
            model: {
                images: {title: "Images", type: "images"},
                title: {title: "Name"},
                service_type: {title: "Service Type"},
                category_id: {
                    title: "Category",
                    type: "picker",
                    module: "categories",
                    caption: "category_name"
                },
                category_name: {title: "Category"},
                parent_id: {title: "Complex"},
                user: {
                    title: "Owner",
                    type: "picker",
                    module: "users",
                    caption: [
                        "user.name",
                        {static: " "},
                        "user.family"
                    ]
                },
                description: {title: "Description"},
                city_id: {
                    title: "City",
                    type: "picker",
                    module: "cities",
                    caption: "city_name"
                },
                city_name: {title: "City"},
                address: {title: "Address"},
                code: {title: "Code"},
                phone: {title: "Tel"},
                email: {title: "Email"},
                web: {title: "Website"},
                telegram: {title: "Telegram"},
                instagram: {title: "Instagram"},
                create_date: {title: "Registration Date"},
                last_update: {title: "Update Time"},
                person_type: {
                    title: "Type",
                    type: "radio",
                    items: [
                        {key: true, value: "Company"},
                        {key: false, value: "Person"}
                    ]
                },
                postal_code: {title: "Postal Code"},
                location: {title: "Location", type: "map"},
                coverage_type: {title: "Coverage"},
                fax: {title: "Fax"},
                category_type: {
                    title: "Business Type",
                    type: "radio",
                    items: [
                        {key: "private", value: "Private"},
                        {key: "public", value: "Public"}
                    ]
                },
                confirmed: {title: "Confirmed", type: "checkbox"},
                admin_note: {title: "Reject reason"},
                tags: {
                    title: "Tags",
                    type: "tag_data",
                    foreign: {
                        module: "tags",
                        path: "/tags",
                        field: "id",
                        result: ["name"]
                    }
                },
                url_name: {title: "URL"},
                business_type: {
                    title: "Type",
                    type: "select",
                    items: [
                        {key: "services", value: "Service-Based"},
                        {key: "products", value: "Goods-Based"},
                        {key: "general", value: "General"}
                    ]
                }
            },
            picker: {
                params: ["title"],
                caption: ["title"],
                fields: [
                    {name: "title"},
                    {
                        title: "Owner",
                        type: "multiple",
                        separator: " ",
                        result: [
                            {name: "user.name", type: "nested"},
                            {name: "user.family", type: "nested"}
                        ]
                    },
                    {name: "city_name"}
                ]
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "images"},
                {name: "title"},
                {name: "business_type"},
                {name: "service_type"},
                {name: "category_name"},
                {
                    title: "Owner",
                    type: "multiple",
                    separator: " ",
                    result: [
                        {name: "user.name", type: "nested"},
                        {name: "user.family", type: "nested"}
                    ]
                },
                {name: "description", max_length: 20},
                {
                    title: "Place",
                    type: "multiple",
                    separator: " - ",
                    result: [
                        {name: "city_name"},
                        {name: "address", max_length: 20}
                    ]
                },
                {
                    title: "Contact",
                    type: "multiple",
                    style: {"direction": "ltr"},
                    separator: " - ",
                    result: [
                        {name: "code"},
                        {name: "phone"},
                        {name: "email"},
                        {name: "web"},
                        {
                            name: "telegram",
                            type: "social",
                            url: "https://www.t.me/",
                            icon: "telegram"
                        },
                        {
                            name: "instagram",
                            type: "social",
                            url: "https://www.instagram.com/",
                            icon: "instagram"
                        },
                    ]
                },
                {
                    title: "Show",
                    type: "function",
                    value: {
                        func: props.functions.showBiz,
                        params: ["id", "url_name", "confirmed"]
                    }
                },
                {name: "create_date", type: "date"}
            ],
            search: [
                {name: "title"}
            ],
            operations_style: {width: '170px'},
            operations: ["add", "edit", "remove", "confirm"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "images"},
                {name: "title"},
                {name: "description", type: "textarea"},
                {name: "business_type"},
                {name: "category_id"},
                {name: "tags"},
                {name: "user"},
                {name: "person_type"},
                {name: "city_id"},
                {name: "address"},
                {name: "postal_code"},
                {name: "location"},
                {name: "code", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "phone", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "fax", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "email", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "web", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "telegram", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "instagram", style: {direction: 'ltr', textAlign: 'left'}},
                {name: "category_type"},
                {name: "url_name"},
                {name: "confirmed"},
                {
                    name: "admin_note",
                    type: "function",
                    value: {
                        func: props.functions.adminNotes,
                        params: ["id", "confirmed", "admin_note"]
                    }
                },
            ],
        },
    };
};

export default services;