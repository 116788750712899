const users = props => {
    return {
        base: {
            entities: "کاربران",
            entity: "کاربر",
            module: "users",
            path: "/users",
            confirm_field: "confirmed",
            confirm_extra_fields: ["mobile_confirmed"],
            picker: {
                params: ["name", "family"],
                caption: [
                    "name",
                    {static: " "},
                    "family"
                ],
                fields: [
                    {name: "pic"},
                    {name: "name"},
                    {name: "family"},
                    {name: "mobile"},
                    {name: "email"},
                ]
            },
            model: {
                pic: {title: "تصویر", type: "image", width: 400, height: 400},
                cover: {title: "کاور", type: "image", width: 600, height: 300},
                name: {title: "نام"},
                family: {title: "نام خانوادگی"},
                code: {title: "کد کشور"},
                mobile: {title: "موبایل"},
                password: {title: "رمز عبور", type: "password"},
                activation_code: {title: "کد فعالسازی"},
                gender: {
                    title: "جنسیت",
                    type: "select",
                    items: [
                        {key: true, value: "مرد"},
                        {key: false, value: "زن"},
                    ]
                },
                b_date: {title: "تاریخ تولد", type: "date"},
                coins: {title: "سکه ها", type: "formatted_number"},
                email: {title: "ایمیل"},
                bio: {title: "بیوگرافی", type: "textarea"},
                role: {title: "نقش"}
            }
        },
        list: {
            page: props.page,
            search: [
                {name: "name"},
                {name: "family"},
                {
                    name: "mobile",
                    regex_type: "start"
                },
                {name: "email"},
                {
                    name: "role",
                    field_type: "select",
                    source_data: props.config ? props.config.ROLES : []
                },
            ],
            fields: [
                {name: "cover"},
                {name: "pic"},
                {name: "name"},
                {name: "family"},
                {name: "mobile"},
                {name: "activation_code"},
                {name: "gender"},
                {name: "b_date"},
                {name: "coins"},
                {name: "email"},
                {name: "create_date", title: "تاریخ عضویت", type: "date"},
                {name: "create_date", title: "زمان عضویت", type: "time"},
            ],
            export_fields: [
                {name: "name"},
                {name: "family"},
                {name: "mobile"},
                {name: "activation_code"},
                {name: "gender"},
                {name: "b_date", type: "date"},
                {name: "coins"},
                {name: "email"},
            ],
            custom_operations: [
                {
                    className: 'primary',
                    caption: {icon: 'MailIcon'},
                    click: {
                        func: props.functions.showSendMessage,
                        params: ["id", "name", "family"]
                    }
                }
            ],
            operations: ["add", "edit", "remove", "confirm"],
            operations_style: {width: "220px"}
        },
        item: {
            id: props.id,
            fields: [
                {name: "cover"},
                {name: "pic"},
                {name: "name"},
                {name: "family"},
                {name: "mobile"},
                {name: "password"},
                {name: "activation_code"},
                {name: "gender"},
                {name: "b_date"},
                {name: "email"},
                {name: "bio"}
            ]
        }
    };
};

export default users;