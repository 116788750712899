import Loki from 'lokijs';
import LokiIndexedAdapter from 'lokijs/src/loki-indexed-adapter';

export default class DB {
    constructor(dbName) {
        this.dbName = dbName;
    }

    initializeDB = (otherProps = {}) => {
        return new Promise(resolve => {
            let idbAdapter = new LokiIndexedAdapter();
            let db = new Loki(this.dbName, {
                autoload: true,
                autosave: true,
                autosaveInterval: 3000,
                adapter: idbAdapter
            });
            resolve({db, otherProps});
        });
    };

    getCollection = (collectionName, otherProps = {}) => {
        return new Promise(resolve => {
            this.initializeDB(otherProps).then(({db, otherProps}) => {
                db.loadDatabase({}, () => {
                    resolve({collection: db.getCollection(collectionName), otherProps});
                });
            });
        });
    };

    addCollection = (collectionName, options = null) => {
        return new Promise(resolve => {
            this.initializeDB().then(({db, otherProps}) => {
                resolve(db.addCollection(collectionName, options));
            });
        });
    };

    getCount = (collection, query = null) => {
        return new Promise(resolve => {
            resolve(collection.count(query));
        });
    };

    getData = (collectionName, query = null, otherProps = {}) => {
        return new Promise(resolve => {
            this.getCollection(collectionName, otherProps).then(({collection, otherProps}) => {
                resolve({list: collection.find(query), otherProps});
            });
        });
    };

    insert = (collection, doc) => {
        return new Promise(resolve => {
            let docs = collection.insert(doc);
            resolve(docs.length > 0);
        });
    };
}