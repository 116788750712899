import React, {useContext, useEffect, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import {PrivateRoute} from "./components";
import {FuncProvider, DBProvider} from "./providers";
import Layout from "./layout/layout";
import {Context} from "./providers/mainProvider";
import * as models from "./models/";
import * as pageComponents from "./pages/";
import Pages from "./pages";

export default function App() {
    let pages = Pages();
    const {fullName} = useContext(Context);
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
        let rt = [];
        let path, operations;
        for(let i = 0; i < pages.length; i++){
            for(let j = 0; j < pages[i].options.length; j++){
                if(pages[i].options[j].custom_route) continue;
                path = pages[i].options[j].path;
                operations = models[pages[i].options[j].module] && models[pages[i].options[j].module]({functions: {}}).list ?
                    models[pages[i].options[j].module]({functions: {}}).list.operations
                    :
                    [];
                if(operations && operations.indexOf("add") > -1){
                    rt.push(
                        <PrivateRoute
                            key={path + '_add'}
                            path={path + '/add'}
                            renderComponent={
                                pages[i].options[j].component ?? pages[i].options[j].module
                            }
                            component={pageComponents.Page}
                        />
                    );
                }
                if(operations && operations.indexOf("edit") > -1){
                    rt.push(
                        <PrivateRoute
                            key={path + '_edit'}
                            path={path + '/edit/:id'}
                            renderComponent={
                                pages[i].options[j].component ?? pages[i].options[j].module
                            }
                            component={pageComponents.Page}
                        />
                    );
                }
                rt.push(
                    <PrivateRoute
                        key={path}
                        exact path={path}
                        renderComponent={
                            pages[i].options[j].component ?? pages[i].options[j].module
                        }
                        component={pageComponents.Page}
                    />
                );
            }
        }
        setRoutes(rt);
    }, []);

    return (
        <Router>
            <Layout>
                <DBProvider>
                    <FuncProvider props={{fullName}}>
                        <Switch>
                            <Route path="/login" component={pageComponents.Login}/>
                            <PrivateRoute exact path="/" component={pageComponents.Dashboard}/>
                            <PrivateRoute path="/permissions/add" renderComponent="permissions" component={pageComponents.Permissions}/>
                            <PrivateRoute path="/permissions/edit/:id" renderComponent="permissions" component={pageComponents.Permissions}/>
                            <PrivateRoute exact path="/permissions" renderComponent="permissions" component={pageComponents.Page}/>
                            {routes.map(route => {
                                return route;
                            })}
                        </Switch>
                    </FuncProvider>
                </DBProvider>
            </Layout>
        </Router>
    );
}
