import React from "react";
import {xhr, DB} from "../components";

export const DBContext = React.createContext({});
DBContext.displayName = "DBContext";
const updateInterval = 24 * 3600 * 1000; // 24 hours

export default class DBProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collections: ['cities', 'categories'],
            getData: this.getData
        };
    }

    componentDidMount() {
        this.initializeCollection({
            collectionName: 'cities',
            options: {
                indices: ['last_update']
            },
            XHRProps: {
                url: 'cities',
                data: {
                    conditions: {confirmed: true},
                    fields: JSON.stringify(['name', 'last_update'])
                },
                perPage: 10000
            }
        }).then(status => {
            // if status is true everything is ok!
        });
        this.initializeCollection({
            collectionName: 'categories',
            options: {
                indices: ['last_update']
            },
            XHRProps: {
                url: 'categories',
                data: {
                    fields: JSON.stringify(['name', 'last_update'])
                },
                perPage: 10000
            }
        }).then(status => {
            // if status is true everything is ok!
        });
    }

    initializeCollection = props => {
        let db = new DB(props.collectionName);
        return new Promise(resolve => {
            db.getCollection(props.collectionName).then(({collection, otherProps}) => {
                if(collection){
                    db.getCount(collection).then(count => {
                        if(count === 0){
                            this.fillCollection(db, {
                                XHRProps: props.XHRProps,
                                collection
                            }).then(status => resolve(status));
                        }else{
                            this.checkForUpdates(db, props);
                            resolve(true);
                        }
                    });
                }else{
                    db.addCollection(props.collectionName, props.options).then(collection => {
                        this.fillCollection(db, {
                            XHRProps: props.XHRProps,
                            collection
                        }).then(status => resolve(status));
                    });
                }
            });
        });
    };

    fillCollection = (db, props) => {
        return new Promise(resolve => {
            new xhr(props.XHRProps).GetMany(response => {
                if(response.status){
                    db.insert(props.collection, response.data.list).then(status => {
                        if(status){
                            localStorage.setItem('last_update', new Date().getTime());
                            resolve(true);
                        }
                    });
                }
            });
        });
    };

    checkForUpdates = (db, props) => {
        if(this.checkForInterval()){
            return new Promise(resolve => {
                let {XHRProps} = props;
                XHRProps.data.fields = JSON.stringify(['last_update']);
                XHRProps.data.sort = JSON.stringify({'last_update': -1});
                XHRProps.perPage = 1;
                new xhr(props.XHRProps).GetMany(response => {
                    if(response.status){
                        let now = new Date().getTime();
                        localStorage.setItem('last_update', now.toString());
                        let serverLastUpdate = response.data.list[0].last_update;
                        this.getCollectionLastUpdate(db, props.collectionName).then(clientLastUpdate => {
                            if(serverLastUpdate !== clientLastUpdate){
                                this.updateCollection();
                            }else{
                                // It is updated!
                            }
                        });
                    }
                });
            });
        }
    };

    getCollectionLastUpdate = (db, collectionName) => {
        return new Promise(resolve => {
            db.getCollection(collectionName).then(({collection, otherProps}) => {
                let data = collection.chain().simplesort('last_update', true).limit(1).data();
                resolve(data[0].last_update);
            });
        });
    };

    checkForInterval = () => {
        let now = new Date().getTime();
        let lastUpdate = parseInt(localStorage.getItem('last_update'));
        if(!lastUpdate){
            localStorage.setItem('last_update', now);
        }
        return (now - lastUpdate >= updateInterval);
    };

    updateCollection = props => {
        // TODO: update local collection from server by last_update
        console.log(props);
    };

    getData = (collectionName, otherProps = {}) => {
        let db = new DB(collectionName);
        return (db.getData(collectionName, {}, otherProps));
    };

    render() {
        return (
            <DBContext.Provider value={this.state}>
                {this.props.children}
            </DBContext.Provider>
        );
    }
}
