const categories = props => {
    return {
        base: {
            entities: "Categories",
            entity: "Category",
            module: "categories",
            path: "/categories",
            model: {
                image: {title: "Image", type: "image", width: 100, height: 100},
                name: {title: "Name"},
                parent_id: {
                    title: "Parent",
                    type: "picker",
                    module: "categories",
                    caption: "parent_name"
                },
                type: {
                    title: "Type",
                    type: "select",
                    items: [
                        {key: "private", value: "Private"},
                        {key: "public", value: "Public"}
                    ]
                },
                default: {title: "Default", type: "checkbox"},
            },
            picker: {
                params: ["name"],
                caption: ["name"],
                fields: [
                    {name: "image"},
                    {name: "name"}
                ]
            }
        },
        list: {
            page: props.page,
            fields: [
                {name: "image"},
                {name: "name"},
                {name: "parent_id"},
                {name: "type"},
                {name: "default"},
            ],
            search: [
                {name: "name"}
            ],
            operations: ["add", "edit", "remove"]
        },
        item: {
            id: props.id,
            fields: [
                {name: "image"},
                {name: "name"},
                {name: "parent_id"},
                {name: "type"},
                {name: "default"},
            ]
        }
    };
};

export default categories;