import React, {useContext} from "react";
import {Link} from "react-router-dom";
import moment from "moment-jalaali";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faTelegram} from '@fortawesome/free-brands-svg-icons'
import {faCheck} from "@fortawesome/free-solid-svg-icons";

export default function processField(props) {
    let {
        row,
        field,
        model,
        foreignFields,
        imageShow,
        setImageShow,
        foreignValues
    } = props.props;
    if (!field) return;

    const socials = {
        "telegram": faTelegram,
        "instagram": faInstagram
    };

    const showNestedField = (row, field) => {
        let nested_fields = field.name.split(".");
        let value = row[nested_fields[0]];
        if(value) {
            for (let i = 1; i < nested_fields.length; i++) {
                if (nested_fields[i]) {
                    value = value[nested_fields[i]];
                }
            }
        }
        return value;
    };

    const process = field => {
        let type = '';
        let fieldModel = {};
        if(model){
            fieldModel = model[field.name];
            if(fieldModel){
                type = fieldModel.type;
            }
        }
        if(field.type){
            type = field.type;
        }
        let result = '';
        let value = '';
        switch (type) {
            case "nested":
                result = showNestedField(row, field);
                break;
            case "image":
                if (row[field.name]) {
                    result = <img
                        src={row[field.name]}
                        className="small-icon"
                        onClick={() => {
                            setImageShow(imageShow.changeBatch({
                                image: {
                                    src: row[field.name],
                                    show: true
                                }
                            }));
                        }}
                        alt={row[field.name]}
                    />;
                }
                break;
            case "images":
                if (row[field.name] && row[field.name][0]) {
                    result = <img
                        src={row[field.name][0]}
                        className={row[field.name].length > 1 ? "small-icon multiple-images" : "small-icon"}
                        onClick={() => setImageShow(imageShow.changeBatch({
                            images: imageShow.images.changeBatch({
                                src: row[field.name],
                                show: true
                            })
                        }))}
                        alt={row[field.name]}
                    />;
                }
                break;
            case "foreign_array":
                if (foreignFields && foreignFields[fieldModel.foreign.module]) {
                    let res = "";
                    if (row[field.name]) {
                        for (let i = 0; i < row[field.name].length; i++) {
                            res = foreignFields[fieldModel.foreign.module][row[field.name][i]];
                            if (res) {
                                result = <span>
                                    {result}{' '}
                                    <Link to={fieldModel.foreign.path + "/edit/" + row[field.name][i]} target="_blank"
                                          rel="noopener noreferrer" className="btn btn-info"
                                          style={{marginBottom: "5px"}}>{res}</Link>
                                </span>;
                            }
                        }
                    }
                }
                break;
            case "foreign":
            case "select_search":
                if (foreignFields && foreignFields[fieldModel.foreign.module] && foreignFields[fieldModel.foreign.module][row[field.name]]) {
                    result = <Link
                        to={fieldModel.foreign.path + "/edit/" + row[field.name]}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-info"
                        style={{marginBottom: "5px"}}
                    >
                        {foreignFields[fieldModel.foreign.module][row[field.name]]}
                    </Link>;
                }
                break;
            case "multiple":
                let temp = '';
                for (let i = 0; i < field.result.length; i++) {
                    value = '';
                    if (field.result[i].title) {
                        value = field.result[i].title + ' : ';
                    }
                    temp = process(field.result[i]);
                    if(!temp) continue;
                    result = <>{result}{i < field.result.length ? field.separator : ''}{value}{temp}</>;
                }
                break;
            case "select":
            case "radio":
                let a = fieldModel.items.filter(item => item.key === row[field.name])[0];
                if (a) result = a.value;
                else result = '';
                break;
            case "convert":
                result = field.convert[row[field.name]];
                break;
            case "function":
                result = field.value.func(field.value.params.map(param => {
                    if(Array.isArray(param)){
                        let res = param.map(p => {
                            if(p.static){
                                return p.static;
                            }else{
                                return row[p];
                            }
                        });
                        return res.join('');
                    }else{
                        if (param.static) {
                            return param.static;
                        } else {
                            return row[param];
                        }
                    }
                }));
                break;
            case "static":
                result = field.value;
                break;
            case "date":
                result = row[field.name] ? moment(row[field.name], "YYYY/MM/DD HH:mm:ss").format("jYYYY/jMM/jDD") : '';
                break;
            case "time":
                result = row[field.name] ? moment(row[field.name], "YYYY/MM/DD HH:mm:ss").format("HH:mm:ss") : '';
                break;
            case "timeago":
                result = row[field.name] ? moment(row[field.name], "YYYY/MM/DD HH:mm:ss").fromNow() : '';
                break;
            case "formatted_number":
                result = row[field.name] ? new Intl.NumberFormat().format(row[field.name]) : '';
                break;
            case "social":
                row[field.name] = row[field.name] ? row[field.name].replace('@', '') : '';
                result = row[field.name] ?
                    row[field.name].indexOf(field.url) > -1 ?
                        <a href={row[field.name]} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={socials[field.icon]} size="lg"/></a>
                        :
                        <a href={field.url + row[field.name]} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={socials[field.icon]} size="lg"/></a>
                    :
                    '';
                break;
            case "checkbox":
                result = row[field.name] ? <FontAwesomeIcon icon={faCheck} size="lg" color="green"/> : '';
                break;
            case "picker":
                result = <a href={`/${fieldModel.path ?? fieldModel.module}/edit/${row[field.name]}`} target="_blank" rel="noopener noreferrer">{fieldModel.caption ? row[fieldModel.caption] : row[field.name]}</a>;
                break;
            case "json":
                result = JSON.stringify(row[field.name]);
                break;
            case "select_data":
                let fov = {...foreignValues[field.name]};
                let item = fov ? fov.filter(fv => fv.id === row[field.name])[0] : null;
                result = item ? item.name : '';
                break;
            default:
                result = row[field.name];
        }
        if (field.max_length) {
            if (row[field.name]) {
                result = row[field.name].length > field.max_length ? <span title={result}>{result.substring(0, field.max_length) + "..."}</span> : result;
            }
        }
        if (!result) {
            result = '';
        }
        return result;
    };

    return process(field);
};
