import React from "react";
import Cookies from "../components/cookies";
import {Alert, Button, Form, Modal, Tab, Tabs, Table} from "react-bootstrap";
import {Loading, xhr} from "../components";
import ReactJson from "react-json-view";
import ReactHtmlParser from "react-html-parser";

export const FuncContext = React.createContext({});

const adminNotes = [
    "Business image doest not match the business",
    "Business title does not match the business",
    "Location does not match the address",
];
const admin_note_header = "Dear user\n" +
    "Your business did not confirmed cause of these reasons:\n" +
    "\n";
const admin_note_footer = "\n" +
    "Please update the information and resubmit.\n" +
    "Thanks\n" +
    "Support Team";


export default class FuncProvider extends React.Component {

    constructor(props) {
        super(props);
        this.fullName = props.props.fullName;
        this.state = {
            msg: {
                msgId: null,
                msgFrom: '',
                msgTo: '',
                msgText: '',
                msgResult: ''
            },
            msgLoading: false,
            setValue: this.setValue,
            modal: {
                title: '',
                body: '',
                show: false
            },
            admin_note: '',
            admin_notes: [],
            functions: {
                showSendMessage: this.showSendMessage,
                changeURL: this.changeURL,
                showLogInfo: this.showLogInfo,
                mobileException: this.mobileException,
                deviceInformation: this.deviceInformation,
                newsButton: this.newsButton,
                showBiz: this.showBiz,
                buttonShowLink: this.buttonShowLink,
                adminNotes: this.adminNotes,
            }
        };
        this.msgRef = React.createRef();
    }

    showSendMessage = args => {
        let {msg, msgLoading} = this.state;
        const CookieObj = new Cookies();
        this.setState({msg: msg.changeBatch({
            msgId: args.id,
            msgFrom: CookieObj.getCookie('UNAME'),
            msgTo: this.fullName(args.name, args.family)
        })}, () => {
            this.setState({modal: {
                title: "ارسال پیام به " + msg.msgTo,
                body: <>
                    {msg.msgResult}
                    <Form.Group>
                        <Form.Control
                            as="textarea"
                            name="msg"
                            ref={this.msgRef}
                            onChange={this.changeMsg}
                            rows={5}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Button variant="primary" onClick={this.sendMsg} disabled={msgLoading}>{msgLoading ? <Loading/> : 'ارسال'}</Button>
                    </Form.Group>
                </>,
                show: true
            }});
        });
    };

    changeMsg = () => {
        this.setState({msg: this.state.msg.change('msgText', this.msgRef.current.value)});
    };

    sendMsg = () => {
        this.setState({msgLoading: true});
        let {msg} = this.state;
        const CookieObj = new Cookies();
        let data = {
            user_id: msg.msgId,
            user_name: msg.msgFrom,
            message: msg.msgText,
            from: CookieObj.getCookie('UID')
        };
        new xhr({
            url: 'chat',
            data
        }).Post(response => {
            let msgResult = '';
            if(response.status){
                msgResult = <Alert variant="success">پیام با موفقیت ارسال شد.</Alert>;
            }else{
                msgResult = <Alert variant="danger">{response.note}</Alert>
            }
            this.setState({
                msg: msg.change('msgResult', msgResult),
                msgLoading: false
            });
            setTimeout(() => {
                this.hideModal();
            }, 1000);
        });
    };

    changeURL = args => {
        let tokenPos = args[0].indexOf("token=");
        let result = args[0];
        if(tokenPos > -1){
            let startPart = args[0].substr(0, tokenPos + 6);
            let restPart = args[0].substr(tokenPos);
            let afterTokenPos = restPart.indexOf('&');
            if(afterTokenPos > -1){
                let endPart = args[0].substr(afterTokenPos + startPart.length - 6);
                result = startPart + "[TOKEN]" + endPart;
            }else{
                result = startPart + "[TOKEN]";
            }
        }
        return <a title={args[0]} href={"http://server1.onmiz.org/v2/" + args[0]} target="_blank" rel="noopener noreferrer">{result.substr(0, 100)}</a>;
    };

    showLogInfo = (args) => {
        if(!args.id) return;
        new xhr({
            url: 'logs',
            data: {id: args.id},
            server: 'log'
        }).GetOne(item => {
            if(item){
                this.setState({modal: {
                    title: args.title,
                    body: <Tabs>
                        <Tab eventKey={1} title="پارامترها"><ReactJson src={item.params}/></Tab>
                        <Tab eventKey={2} title="خروجی"><ReactJson src={item.output}/></Tab>
                        <Tab eventKey={3} title="جزئیات"><ReactJson src={item.details}/></Tab>
                        <Tab eventKey={4} title="نتیجه"><ReactJson src={item.results}/></Tab>
                        <Tab eventKey={5} title="سند"><ReactJson src={item.doc}/></Tab>
                        <Tab eventKey={6} title="توکن">{item.token}</Tab>
                        <Tab eventKey={7} title="پارامترهای اولیه"><ReactJson src={item.original_params}/></Tab>
                    </Tabs>,
                    show: true
                }});
            }
        });
    };

    mobileException = args => <>{args[0].msg}<br/>{args[0].method}</>;

    deviceInformation = args => {
        this.setState({modal: {
            title: "اطلاعات دستگاه",
            body: args.device_info ?
                <Table striped responsive>
                    <tbody>
                    {Object.keys(args.device_info).map(key =>
                        <tr>
                            <td>{key}</td>
                            <td>{args.device_info[key]}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                :
                "",
            show: true
        }});
    };

    newsButton = args => {
        return <Button onClick={() => this.showNews(args[0])}>متن خبر</Button>;
    };

    showNews = news => {
        this.setState({modal: {
            title: "متن خبر",
            body: ReactHtmlParser(news),
            show: true
        }});
    };

    showBiz = args => {
        return args[1] ?
            <Button
                onClick={() => window.open("https://onmiz.com/details/" + args[0])}
                style={{wordBreak: 'normal'}}
            >
                نمایش
            </Button>
            :
            null;
    };

    buttonShowLink = args => {
        if(!args[0]) return;
        return <Button variant="outline-info" onClick={() => this.setState({modal: {
            title: 'لینک دکمه',
            body: args[1],
            show: true
        }})}>{args[0]}</Button>;
    };

    setValue = val => {
        this.setState(val);
    };

    hideModal = () => this.setState({
        modal: {
            title: '',
            body: '',
            show: false
        },
        msg: {
            msgId: null,
            msgFrom: '',
            msgTo: '',
            msgText: '',
            msgResult: ''
        }
    });

    handleChangeAdminNotes = (event, index, fieldChange) => {
        let target = event.target;
        let {admin_notes} = this.state;
        admin_notes[index] = target.checked;
        let admin_note = admin_note_header;
        for(let i = 0; i < admin_notes.length; i++){
            if(admin_notes[i]){
                admin_note += "- " + adminNotes[i] + "\n";
            }
        }
        admin_note += admin_note_footer;
        this.setState({admin_notes});
        fieldChange({
            target: {
                value: admin_note
            }
        });
    };

    adminNotes = args => {
        this.setState({admin_note: args.admin_note});
        return <>
            <Form.Group>
                {adminNotes.map((adminNote, index) =>
                    <Form.Check
                        type="checkbox"
                        key={`admin-note-${index}`}
                        checked={this.state.admin_notes[index]}
                        onChange={(e) => this.handleChangeAdminNotes(e, index, args.fieldChange)}
                        label={adminNote}
                        id={`checkbox-${index}`}
                    />
                )}
            </Form.Group>
            <Form.Group>
                <Form.Control
                    as="textarea"
                    rows={10}
                    name="admin_note"
                    value={args.admin_note}
                    onChange={args.fieldChange}
                />
            </Form.Group>
        </>
    };

    render() {
        let {modal} = this.state;
        return (
            <FuncContext.Provider value={this.state}>
                <Modal show={modal.show} onHide={this.hideModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>{modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modal.body}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={this.hideModal}>Close</Button>
                    </Modal.Footer>
                </Modal>
                {this.props.children}
            </FuncContext.Provider>
        );
    }
}
