import {useContext} from "react";
import {Context} from "./providers/mainProvider";

export default function Pages(){
    const {locale} = useContext(Context);
    let id = 1;
    return [
        {
            id: id++,
            title: locale.basic_info,
            options: [
                {id: id++, module: "cities", title: locale.cities, path: "/cities"},
                {id: id++, module: "categories", title: locale.categories, path: "/categories"},
                {id: id++, module: "services", title: locale.businesses, path: "/businesses"},
            ]
        },
    ];
}